import React from 'react';
import { Accordion, Button, Container, Row, Col, Table  } from 'react-bootstrap';

export const JobAccess  = ({jobData}) =>
{   

    return(
        <Container fluid className="p-5 bg-light">
            <Row xs={12} sm={12} md={12} lg={12}>
            <Col xs={{offset:10, span:2}} sm={{offset:10, span:2}} md={{offset:10, span:2}} lg={{offset:10, span:2}}>
                    <Button variant='primary' disabled>Add User</Button>
                </Col>
            </Row>
            <Accordion className="bg-light">
                <Accordion.Item eventKey="0" className="bg-light">
                    <Accordion.Header className="bg-light">John Libront</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <th>{"Access"}</th>
                                <th>{"Access Level"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>View</td>
                                <td>Enabled</td>
                            </tr>
                            <tr>
                                <td>Edit</td>
                                <td>Enabled</td>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="bg-light">
                    <Accordion.Header className="bg-light">Srinu Penubarthi</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <th>Access</th>
                                <th>"Access Level"</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>View</td>
                                <td>Enabled</td>
                            </tr>
                            <tr>
                                <td>Edit</td>
                                <td>Enabled</td>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="bg-light">
                    <Accordion.Header className="bg-light">Lupco Blazeski</Accordion.Header>
                    <Accordion.Body className="bg-light">
                    <Table striped  bordered hover size = "sm">
                        <thead>
                            <tr>
                                <th>Access</th>
                                <th>Access Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>View</td>
                                <td>Enabled</td>
                            </tr>
                            <tr>
                                <td>Edit</td>
                                <td>Disabled</td>
                            </tr>
                        </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
    
}