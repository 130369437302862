import React, {useEffect, useState} from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, Outlet } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import { getUser } from '../../auth';
import { store } from '../../auth';
import { Button, Container, Nav, Navbar, Offcanvas, Form } from 'react-bootstrap';
import { UserDropdownComponent } from '../UserDropdownComponent';

import { useDummyPermissionContext } from '../../auth/AuthContext';

export const NavigationBar = () => {

  const { hasPermission, setHasPermission } = useDummyPermissionContext();
  const auth = useAuth();
  const handleLogout = async () =>
  {
    await auth.removeUser();
    auth.signoutRedirect();
  }

  useEffect(() => {
    const handleStorageChange = () => {
      if(getUser() === null){
        auth.signoutRedirect();
      }
    }
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    }

  }, [])

  const handleChangeAuth = () =>
  {
    setHasPermission(!hasPermission)
  }

  return <Navbar key={'md'} expand='md' className="custom-navbar container-fluid">
    <Container fluid>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler"><i className="bi bi-list large-icon" style={{"color":"white"}}></i></Navbar.Toggle>
      <Navbar.Brand href="/" className="me-auto navbar_brand_text">Milliman Gridstep</Navbar.Brand>
      <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="start"
          className="navbar-offcanvas"
        >
          <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`} className="navbar_brand_text">
                Milliman Gridstep
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3">
              
                  <Nav.Link as={NavLink}  to="/jobs" className="navbar_text">Jobs</Nav.Link>
                  <Nav.Link as={NavLink} to="/files" className='navbar_text'>Files</Nav.Link>
              
                  <Nav.Link as={NavLink} to="/about" className='navbar_text'>About</Nav.Link>
              
              </Nav>
            </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Nav  className="ms-auto" data-bs-theme="dark" >
        <Nav.Link className="navbar_text">
          <UserDropdownComponent/>
        </Nav.Link>
        <Nav.Link>
          <Button className="button-header" onClick={handleLogout}><i className="bi bi-box-arrow-right navbar_text"/></Button>
        </Nav.Link>
        <Nav.Item>
        <Form.Check type="switch"
            id="custom-switch"
            checked={hasPermission}
            label={!hasPermission?"Read Only Off":"Read Only On"}
            className="auth-switch" onChange={handleChangeAuth}
            style={{color :"white"}}/>
        </Nav.Item>
      </Nav> 
    </Container>
  </Navbar>
}