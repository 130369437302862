import config from '../config';
import { InMemoryWebStorage, WebStorageStateStore, User } from 'oidc-client-ts';

export const store = {
    store: typeof window !== "undefined" ? window.localStorage :  new InMemoryWebStorage(),
}

export const oidcConfig = {
  authority: config.AUTH_URL,
  client_id: config.CLIENT_ID,
  redirect_uri:  `${window.location.protocol}//${window.location.host}/`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/logged_out`,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore(store),
  onSigninCallback: function (_user) {
    
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }
};

export function getUser() {
  const oidcStorage = store.store.getItem(`oidc.user:${config.AUTH_URL}:${config.CLIENT_ID}`)
  if (!oidcStorage) {
      return null;
  }
  
  return User.fromStorageString(oidcStorage);
}

export function getManageProfileRoute() {
  return `${config.AUTH_URL}/Account/Manage`;
}