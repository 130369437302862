import React from 'react';
import { Container, Table  } from 'react-bootstrap';
import {StyledCell } from '../StyledCells';
import { taskProgressFormatter } from '../../library/formatters';

export const JobProgress  = ({jobData}) =>
{ 
    var taskSummary = jobData.taskSummary;
    return(
        <Container fluid className="bg-light"> 
            
            <Table striped size="sm">
            <thead>
                <tr>
                    <th>Task Status</th> 
                    <th>% of Tasks</th>
                </tr>
            </thead>
                <tbody>
                    <tr><StyledCell cellContent={"Completed: "}/><td>{taskSummary.total !== 0? taskProgressFormatter(taskSummary.completed/taskSummary.total):"0%"}</td> </tr>
                    <tr><StyledCell cellContent={"Failed: "}/><td>{taskSummary.total !== 0? taskProgressFormatter(taskSummary.failed/taskSummary.total) : "0%"}</td></tr>
                    <tr><StyledCell cellContent={"Progress Made: "}/><td>{`${(taskSummary.progress * 100).toFixed(2)}%`}</td></tr>
                    <tr><StyledCell cellContent={"Tasks Started: "}/><td>{taskSummary.total !== 0? taskProgressFormatter(taskSummary.started/taskSummary.total): "0%"}</td></tr>
                </tbody>
            </Table>
        </Container>
    )
}