import ProtectedRoute from '../auth/ProtectedRoute';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Root } from '../components/Root';
import { AboutPage } from '../pages/AboutPage';
import { JobsPage } from '../pages/JobsPage';
import { JobPage } from '../pages/JobPage';
import { FilesPage } from '../pages/FilesPage';
import { ErrorPage } from '../pages/ErrorPage';
import { LoggedOutPage } from '../pages/LoggedOutPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute><Root /></ProtectedRoute>,
    children: [
      {
        index: true,
        element: <Navigate to="/jobs" />
      },
      {
        path: 'jobs', 
        element: <JobsPage/>
      },
      {
        path: 'jobs/:id',
        element: <JobPage />
      },
      {       
        path: 'about',
        element: <AboutPage/>
      },
      {       
        path: 'files',
        element: <FilesPage/>
      },
      {
        path: "*",
        element: <ErrorPage/>
      }
    ]
  },
  {
    path: '/logged_out',
    element: <LoggedOutPage />
  }
]);